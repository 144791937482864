import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getToStoreData } from '../plugins/storage';
import GlobalNavbar from '../components/GlobalNavbar';
import axios from '../plugins/axios';
import styled from 'styled-components';
import usePagination from '../hooks/usePagination ';
import useSorting from '../hooks/useSorting ';
import { TableContainer, TablePagination, TableSortLabel } from '@mui/material';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import toast from 'react-hot-toast';
import SendToAllModal from '../components/SendToAllModal';
import SuccessModal from '../components/SuccessModal';
import SentTestModal from '../components/SentTestModal';
import userContext from '../context/userContext';

const UserList = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [userList, setUserList] = useState(getToStoreData("recipientData") ? JSON.parse(getToStoreData("recipientData")) : []);
  const [userListFilter, setUserListFilter] = useState([]);
  const [groupUserTagList, setGroupUserTagList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [selectGroupTag, setSelectGroupTag] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectGroupTagToggle, setSelectGroupTagToggle] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [modalData, setModalData] = useState("");
  const [recipientList, setRecipientList] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sendRecipientModal, setSendRecipientModal] = useState(false);

  const templateTitle = useRef(null);
  const modalRef = useRef(null);

  const [existingUserList, setExistingUserList] = useState([]);
  const [newUserList, setNewUserList] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { order, orderBy, handleRequestSort, getComparator, sortArray } = useSorting();

  const [showTooltip, setShowTooltip] = useState(false); // Control tooltip condition
  const buttonRef = useRef(null);
  const { isLoading: loading, recipientData } = useContext(userContext)

  // Function to handle individual checkbox selection
  const handleCheckboxChange = (id) => {
    setUserList((prevUserList) =>
      prevUserList.map((user) =>
        user.ID === id ? { ...user, isInvite: !user.isInvite } : user
      )
    );
    setExistingUserList((prevUserList) =>
      prevUserList.map((user) =>
        user.ID === id ? { ...user, isInvite: !user.isInvite } : user
      )
    );
  };

  // Function to select all checkboxes
  const handleSelectAll = (event) => {
    const ExistId = userListFilter.map((d) => d.ID);
    const ExistRecipientData = recipientList.map((curElem) => curElem.user_id);
    const userIdList = userList.filter((curElem) => curElem.isInvite).map((u) => u.user_id);

    if (event.target.checked) {
      setUserList((prevUserList) =>
        prevUserList.map((user) => {
          if (ExistId.includes(user.ID)) {
            return { ...user, isInvite: event.target.checked }
          }
          return user
        })
      );
      setExistingUserList((prevUserList) =>
        prevUserList.map((user) => {
          if (ExistId.includes(user.ID)) {
            return { ...user, isInvite: event.target.checked }
          }
          return user
        })
      );
    } else {
      if (ExistRecipientData.length >= userIdList.length) {
        toast.error("You've already saved the recipients. Please clear the history before deselecting all boxes again.");
        return;
      }
      setUserList((prevUserList) =>
        prevUserList.map((user) => {
          if (!ExistRecipientData.includes(user.ID)) {
            return { ...user, isInvite: false }
          }
          return user;
        })
      );
      setExistingUserList((prevUserList) =>
        prevUserList.map((user) => {
          if (!ExistRecipientData.includes(user.ID)) {
            return { ...user, isInvite: false }
          }
          return user;
        })
      );
    }
  };

  const getUserData = (group_id) => {
    setIsLoading(true);

    let url = `/user_list/${group_id || selectedGroup.id}`;
    if (selectGroupTag && selectCity) {
      url = url + "/" + selectGroupTag + "/" + selectCity
    } else if (!selectGroupTag && selectCity) {
      url = url + "/" + null + "/" + selectCity
    } else if (selectGroupTag && !selectCity) {
      url = url + "/" + selectGroupTag
    }

    axios.get(url).then(async (response) => {

      if (selectGroupTagToggle) {
        const data = response?.data?.flat().map((val) => {
          const usersWithInvite = existingUserList?.find((user) => user.ID === val.ID);

          if (usersWithInvite) {
            return { ...val, isInvite: usersWithInvite.isInvite, email_sent: usersWithInvite.email_sent }
          }
          return val;
        })
        setUserList(data);
      } else {
        setUserList(response?.data?.flat() || []);
        GetRecipientData(group_id);
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getGroupUserTag = (group_id) => {
    axios.get(`/group_user_tags/${group_id}`).then(async (response) => {
      setGroupUserTagList(response.data.flat() || []);
    }).catch((error) => {
      console.log(error);
    });
  }
  const getUserCitiesList = (group_id) => {
    axios.get(`/users_cities/${group_id}`).then(async (response) => {
      setCitiesList(response.data?.cities || []);
    }).catch((error) => {
      console.log(error);
    });
  }

  const GetRecipientData = (group_id) => {

    const userInfoData = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    axios.get(`/user_recipients/${group_id || selectedGroup.id}/${JSON.parse(userInfoData).id}/${communication_uuid}`, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      if (response.data && response.data?.data?.length > 0) {
        const data = response.data?.data.find((val) => {
          return val.communication_uuid === communication_uuid
        })
        if (data) {
          setRecipientList(JSON.parse(data.user_array));
          setShowTooltip(true);
        } else {
          setRecipientList([]);
          setShowTooltip(false);
        }
      } else {
        setRecipientList([]);
        setShowTooltip(false);
      }
    }).catch((error) => {
      setRecipientList([]);
      console.log(error, "network error");
    })
  }

  // Get selected group name from local storage
  useEffect(() => {
    const selectedGroupData = getToStoreData('selectedGroup');
    const communicate_data = getToStoreData("communicate_data");
    if (communicate_data) {
      const data = JSON.parse(communicate_data);
      templateTitle.current = data.title;
    }

    if (selectedGroupData && !loading) {
      setUserList(recipientData?.length !== 0 ? recipientData : getToStoreData("recipientData") ? JSON.parse(getToStoreData("recipientData")) : []);
      setSelectedGroup(JSON.parse(selectedGroupData));
      getGroupUserTag(JSON.parse(selectedGroupData).id);
      getUserCitiesList(JSON.parse(selectedGroupData).id);
      // getUserData(JSON.parse(selectedGroupData).id);
      GetRecipientData(JSON.parse(selectedGroupData).id);
    }
  }, [loading]);


  const handleGroupTagChange = (event) => {
    setSelectGroupTagToggle(true);
    setSelectGroupTag(event.target.value);
  }

  useEffect(() => {
    if (selectGroupTagToggle || selectGroupTag || selectCity) {
      getUserData();
      // GetRecipientData();
    }
  }, [selectGroupTag, selectGroupTagToggle, selectCity])

  useEffect(() => {
    const list = existingUserList.length !== 0 ? existingUserList : userList;
    const data = list.map((d) => {
      const isCheck = recipientList.find((cur) => {
        return cur.user_id === d.ID
      });

      if (isCheck) {
        return { ...d, isInvite: true, email_sent: isCheck.email_sent }
      } else {
        return { ...d, isInvite: false, email_sent: false }
      }
    })
    if (!selectGroupTagToggle) {
      setUserList(data);
    }
    setExistingUserList(data);
  }, [recipientList]);


  useEffect(() => {
    const data = userList.filter((item) => {
      return (
        item.custom_fields?.["First name"]?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.custom_fields?.["Last name"]?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.email_address?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.custom_fields?.Company?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.custom_fields?.city?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.tags?.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
    setUserListFilter(data);
  }, [userList, searchItem])


  useEffect(() => {
    // const data = existingUserList.filter((d) => d.isInvite);
    setNewUserList(recipientList.map((curElem) => {
      return {
        user_id: curElem.user_id,
        email_address: curElem.email_address,
        email_sent: curElem.email_sent
      }
    }));
    if (recipientList.length < 1) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [recipientList])

  const handleSaveRecipient = (event) => {
    event.preventDefault();

    const communication_uuid = getToStoreData("communication_uuid");
    const selectGroupData = getToStoreData('selectedGroup');
    const userInfoData = getToStoreData('userInfo');

    const userFilterData = userListFilter.filter((d) => d.isInvite);
    const data = existingUserList.filter((d) => d.isInvite);
    const user_list = data.map((curElem) => {
      return {
        user_id: curElem.ID,
        email_address: curElem.email_address,
        email_sent: curElem.email_sent
      }
    })
    if (userFilterData.length < 1) {
      toast.error("Please select at least one user.")
      return;
    }
    if (recipientCount === 0 && !hasUnsavedChanges && userFilterData.length !== 0) {
      toast.error("You have already sent this email to all these selected recipients.To send again please clear your history.");
      return;
    }
    if (!selectGroupData) return;
    if (!userInfoData) return;
    if (!communication_uuid) return;

    axios.post(`/store_user_lists`, {
      user_array: user_list,
      communication_id: communication_uuid ? communication_uuid : "",
      group_id: JSON.parse(selectGroupData).id.toString(),
      creates_by_user_id: JSON.parse(userInfoData).id.toString()
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      toast.success("You have saved your list");
      GetRecipientData();
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const isAllSelect = useMemo(() => {
    return userListFilter.length > 0 ? userListFilter.every((d) => d.isInvite) : false;
  }, [userListFilter]);

  const ShowSendRecipientModal = () => {
    if (JSON.parse(getToStoreData("is_send_to_all"))) {
      toast.error("All recipients have already been sent.")
      return;
    }
    const userFilterData = userListFilter.filter((d) => d.isInvite);
    const data = existingUserList.filter((d) => d.isInvite);
    const user_list = data.map((curElem) => {
      return curElem.ID
    })
    const new_user_list = recipientList.map((r) => r.user_id)
    const f_user_list = userFilterData.map((curElem) => {
      return curElem.ID
    });

    if (userFilterData.length < 1 && !recipientList.find((v) => f_user_list.includes(v.user_id))) {
      toast.error("Please save your list.")
      return;
    }
    if (!arraysAreEqual(user_list, new_user_list)) {
      toast.error("Selected recipient lists have changed, please save the updated list.");
      return;
    }
    const v = recipientList.find((v) => f_user_list.includes(v.user_id))
    if (recipientCount === 0 && v) {
      toast.error("You have already sent this email to all these selected recipients.To send again please clear your history.");
      return;
    } else {
      setSendRecipientModal(true);
      document.body.classList.add('overflow-hidden');
      setActiveTab("send")
    }
  }

  const hideSendRecipientModal = () => {
    setActiveTab("");
    setSendRecipientModal(false);
    document.body.classList.remove('overflow-hidden');
  }

  const handleSendRecipient = () => {

    const communication_uuid = getToStoreData("communication_uuid");
    const userInfoData = getToStoreData('userInfo');

    if (!communication_uuid) return;
    if (!selectedGroup) return;
    if (!userInfoData) return;

    const new_user_list = newUserList.map((r) => {
      const f_user_list = userList.find((curElem) => {
        return curElem.ID == r.user_id
      });

      return { ...r, first_name: f_user_list?.custom_fields["First name"] }

    })

    setIsSending(true);
    axios.post(`/send-to-selected-recipients`, {
      communication_uuid,
      group_id: selectedGroup.id,
      group_name: selectedGroup.name,
      user_list_array: new_user_list,
      created_by_user_id: JSON.parse(userInfoData).id
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      GetRecipientData();
      toast.success("Your email has been sent to your selected recipients.");
      hideSendRecipientModal();
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    }).finally(() => {
      setIsSending(false);
    })
  }

  const handleWarningModalShow = () => {
    if (recipientList.length > 0) {
      const myModal = new bootstrap.Modal(document.getElementById('warning-modal'));
      setModalData(myModal);
      myModal.show();
    } else {
      toast.error("No history found.")
    }
  }

  const warningModalHide = () => {
    modalData.hide();
    setModalData("");
  }

  const handleClearHistory = () => {

    const userInfoData = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    axios.post(`/clear_user_recipients/${selectedGroup.id}/${JSON.parse(userInfoData).id}/${communication_uuid}`, {}, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      if (response.data) {
        setRecipientList([]);
        setUserList((prevUserList) =>
          prevUserList.map((user) => {
            return { ...user, isInvite: false, email_sent: false }
          })
        );
        setExistingUserList((prevUserList) =>
          prevUserList.map((user) => {
            return { ...user, isInvite: false, email_sent: false }
          })
        );
        toast.success(response.data.message);
        warningModalHide();
      }
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message);
    })
  }

  const recipientCount = useMemo(() => {
    return newUserList.reduce((total, currentValue) => total + (!currentValue.email_sent ? 1 : 0), 0)
  }, [newUserList]);


  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((value, index) => {
      return arr2.find((v) => v === value)
    });
  }

  const unSaveChangeWarning = () => {
    const data = existingUserList.filter((d) => d.isInvite);
    const user_list = data.map((curElem) => {
      return curElem.ID
    })
    const new_user_list = newUserList.map((curElem) => {
      return curElem.user_id
    })
    if (!arraysAreEqual(user_list, new_user_list)) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
    // if (recipientList.length > 0) {
    //   if (newUserList.some((cur) => !user_list?.includes(cur.user_id))) {
    //     setHasUnsavedChanges(true);
    //   } else {
    //     setHasUnsavedChanges(false);
    //   }
    // } else {
    //   newUserList.length < 1 ? setHasUnsavedChanges(false) : setHasUnsavedChanges(true);
    // }
  }

  useEffect(() => {
    unSaveChangeWarning()
  }, [newUserList, existingUserList])

  useEffect(() => {
    const handleTabClose = (event) => {
      if (hasUnsavedChanges) {
        const message = "You are leaving without saving your list. Are you sure?";
        event.preventDefault();
        event.returnValue = message; // For other browsers
        return message; // For older browsers
      }
    };

    window.addEventListener("beforeunload", handleTabClose, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", handleTabClose, { capture: true });
    };
  }, [hasUnsavedChanges]);

  const sendBtnToggle = useMemo(() => {
    const userFilterData = userListFilter.filter((d) => d.isInvite);

    const f_user_list = userFilterData.map((curElem) => {
      return curElem.ID
    });

    return (userFilterData.length < 1 && !recipientList.find((v) => f_user_list.includes(v.user_id))) || showTooltip
  }, [userListFilter, recipientList, showTooltip])

  // Initialize Bootstrap tooltip
  useEffect(() => {
    if (sendBtnToggle && buttonRef.current) {
      const tooltipInstance = new bootstrap.Tooltip(buttonRef.current, {
        title: 'Please save your list',
        trigger: 'hover',
      });
      return () => tooltipInstance.dispose(); // Cleanup tooltip instance
    }
  }, [sendBtnToggle]);

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalVisible(true);
  }

  const closeModal = () => {
    setModalVisible(false);
    setActiveTab("");
  }

  useEffect(() => {
    // Function to handle outside click
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        hideSendRecipientModal(); // Close modal if clicked outside the modal content
      }
    };

    // Attach event listener to detect clicks
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCityChange = (event) => {
    setSelectGroupTagToggle(true);
    setSelectCity(event.target.value);
  }

  return (
    <Wrapper>
      <GlobalNavbar groupName={selectedGroup?.name} />
      <div className="container mt-4">
        <div className="row mb-4">
          <div className="col-lg-5">
            <h4 className='dark_title'>Select Recipients and Send </h4>
          </div>
          <div className="col-lg-7">
            <div className="d-flex rounded overflow-hidden filter-tab" style={{ marginLeft: "-33px" }}>
              <SentTestModal activeTab={activeTab} setActiveTab={setActiveTab} />
              <div style={{ border: '1px var(--bs-border-style) #ffffff' }}></div>
              <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" data-bs-title="Disabled tooltip" ref={buttonRef} style={{ cursor: showTooltip ? "not-allowed" : "pointer" }}>
                <button
                  className={`btn ${activeTab === "send" ? "active" : ""} tab-btn`}
                  type="button" disabled={sendBtnToggle} onClick={ShowSendRecipientModal}
                >
                  <img src="/images/popup-icon.png" alt="" className='img-fluid' width={"25px"} /> Send to saved list
                </button>
              </span>
              {/* <div style={{ border: '1px var(--bs-border-style) #ffffff' }}></div>
              <button type="button" className={`btn ${activeTab === "all" ? "active" : ""} tab-btn`} onClick={() => openModal("all")}><img src="/images/popup-icon.png" alt="" className='img-fluid' width={"25px"} />Send to all</button> */}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 user-filter">
          <div className="d-flex gap-2 flex-wrap mt-2">
            <input type='text' className='form-control' placeholder='Search users' value={searchItem} onChange={(event) => setSearchItem(event.target.value)} />
            <select className='form-select' value={selectGroupTag} onChange={handleGroupTagChange}>
              <option value="" >Select Tags</option>
              {groupUserTagList.map((tag) => (
                <option value={tag.id} key={tag.id}>{tag.tag_name}</option>
              ))}
            </select>
            <select className='form-select' value={selectCity} onChange={handleCityChange}>
              <option value="" >Select City</option>
              {citiesList.map((city, index) => (
                <option value={city} key={index}>{city}</option>
              ))}
            </select>
          </div>
          <div className='d-flex gap-2 mt-2 flex-wrap'>
            <button className="btn btn-Vault btn-clear-all" onClick={handleWarningModalShow}>Clear history</button>
            <button className="btn btn-primary btn-Vault" onClick={handleSaveRecipient}>Save</button>
          </div>
        </div>
        <div className='table-responsive accordion-header'>
          <TableContainer>
            <table className="display table table-hover w-100 mb-1 border border-1">
              <thead className="table-header">
                <tr>
                  <th className="text-start"><TableSortLabel active={orderBy === "firstName"} direction={orderBy === "firstName" ? order : 'asc'} onClick={() => handleRequestSort("firstName")}>First Name</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "lastName"} direction={orderBy === "lastName" ? order : 'asc'} onClick={() => handleRequestSort("lastName")}>Last Name</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "email_address"} direction={orderBy === "email_address" ? order : 'asc'} onClick={() => handleRequestSort("email_address")}>Email</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "company"} direction={orderBy === "company" ? order : 'asc'} onClick={() => handleRequestSort("company")}>Company</TableSortLabel></th>
                  <th className="text-start" style={{ whiteSpace: 'nowrap' }}><TableSortLabel active={orderBy === "tags"} direction={orderBy === "tags" ? order : 'asc'} onClick={() => handleRequestSort("tags")}>Tags</TableSortLabel></th>
                  <th className="text-start" style={{ whiteSpace: 'nowrap' }}><TableSortLabel active={orderBy === "city"} direction={orderBy === "city" ? order : 'asc'} onClick={() => handleRequestSort("city")}>City</TableSortLabel></th>
                  <th>
                    <div className='d-flex align-items-center gap-1'>
                      <input className="form-check-input mt-0" style={{ cursor: "pointer" }} type="checkbox" checked={isAllSelect} onChange={handleSelectAll} />
                      <span>All</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userListFilter.length > 0 && !isLoading && !loading ?
                  sortArray([
                    ...userListFilter.filter(user =>
                      recipientList.some(recipient => recipient.user_id === user.ID)
                    ),
                    ...userListFilter.filter(user =>
                      !recipientList.some(recipient => recipient.user_id === user.ID)
                    )
                  ], getComparator(order, orderBy))
                    .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((user) => (
                      <tr key={user.ID} className={user.isInvite ? 'highlight' : ''}>
                        <td className="px-2 py-2">{user?.custom_fields?.["First name"]}</td>
                        <td className="px-2 py-2">{user?.custom_fields?.["Last name"]}</td>
                        <td className="px-2 py-2">{user.email_address}</td>
                        <td className="px-2 py-2">{user?.custom_fields?.Company}</td>
                        <td className="px-2 py-2">{user.tags ? user.tags : <i className="fa-solid fa-minus"></i>}</td>
                        <td className="px-2 py-2">{user?.custom_fields?.city ? user?.custom_fields?.city : <i className="fa-solid fa-minus"></i>}</td>
                        <td><input className="form-check-input" type="checkbox" id={user.ID} checked={user.isInvite || false} onChange={() => handleCheckboxChange(user.ID)} /></td>
                      </tr>
                    ))
                  : (
                    <tr>
                      <td colSpan={7} className="text-center font-Bukra">{
                        !isLoading && !loading ? " No Data Found" : "Loading..."}</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            count={userListFilter.length}
            page={page}
            className="bg-gray-50"
          />
        </div>
      </div>
      {/* === Send Recipient Modal === */}
      {sendRecipientModal &&
        <div className="modal fade show" style={{ display: 'block', backgroundColor: "rgb(25 28 28 / 50%)" }} >
          <div className="modal-dialog" ref={modalRef} >
            <div className="modal-content" style={{ marginTop: "185px", marginLeft: "140px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Sending to recipients</h5>
              </div>
              <div className="modal-body my-1">
                <p className="text-label">You are sending <strong>{templateTitle.current}</strong> to these <strong>{recipientCount}</strong> recipients. You have already sent this email to <strong>{newUserList.length - recipientCount}</strong> recipients. - all good?</p>
                <hr />
                <div className="mt-3 d-flex gap-3 justify-content-end">
                  <button className="btn btn-primary btn-gray" type="button" disabled={isSending} onClick={hideSendRecipientModal}>Cancel</button>
                  <button type="submit" className="btn btn-Vault" style={{ marginRight: "10px" }} onClick={handleSendRecipient}>{isSending ? "Sending..." : "Yes, Send"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>}
      {/* Clear History Waring Modal */}
      <div className="modal fade" id="warning-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Clear recipients history</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body px-4 py-2 d-flex gap-4">
              <div className="check-icon">
                <img src="/images/Trash-list-account-icon.png" alt="check_icon" className="img-fluid" />
              </div>
              <div className='mt-3 content-right'>
                <p className="text-black">Clearing your history will permanently delete all saved recipients and remove the status of any previously sent invites.</p>
                <h6>This action cannot be undone.</h6>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn clear-history-cancel" onClick={warningModalHide}>Cancel</button>
              <button type="button" className="btn clear-history-confirm px-4" onClick={handleClearHistory}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible &&
        <SendToAllModal closeModal={closeModal} />}
      <SuccessModal successMessage={""} />
    </Wrapper>
  );
};

export default UserList;

const Wrapper = styled.div`
  .table-header tr th {background:#ededed;}
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{ margin-bottom: 0px !important;}
  .form-check-input:checked {background-color: #92edce;border-color: #92edce;}
  .form-check-input{margin-top:10px;cursor: pointer;}
  .form-check-input:focus,.form-check-input:active{box-shadow:unset;filter:unset;}
  .btn-Vault{
    font-weight: 400 !important;
    letter-spacing: 1px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .btn-Vault-secondary{
    background: none;
    border: 1px solid #92edce;
    color: #000;
  }

  .btn-Vault-secondary:hover{
    background: #013321;
    border: 1px solid #013321;
    color: #fff !important;
  }

  .btn-clear{
    background: #ffffff !important;
    color: #c1c1c1 !important;
    border: none;
  }

  .btn-clear-all{
    background-color: #ffffff !important;
    border: none !important;
    color: #000 !important;
  }

  .form-select, .form-control{
    width: 32%;
  }

  .user-filter{
    background: #f3f3f3;
    padding: 23px 10px;
  }
  .user-filter .form-control, .user-filter .form-select {
    border:none;
    /* background: #ffffff; */
  }
  .table > tbody > tr:nth-child(even) > td {background: #dbfef7;}
  .table > tbody > tr > td{color:#424242}
  .MuiTablePagination {color: #016b55;}

  .text-label{
    margin-bottom: 0;
    font-size: 13px;
    background: #92edce;
    padding: 10px 5px 10px 19px;
    border-radius: 5px;
    color: #000000;
  }

  #warning-modal .fa-check{
    font-size: 28px;
    font-weight: bolder;
  }

  #warning-modal .modal-title{
    color: #265344 !important;
    font-size: 17px;
  }

  #warning-modal p{
    font-size: 14px;
    color: #696969;
    /* color: #c5c5c5; */
  }

  #warning-modal .check-icon{
    width: 70px;
    height: auto;
  }
  #warning-modal .content-right{width: calc(100% - 80px)}

  #warning-modal .modal-dialog{
    max-width: 500px;
  }

  @media (max-width: 575px) {
    #warning-modal .modal-dialog{
      max-width: 100% !important;
    }
  }
  #warning-modal .modal-body{
    /* flex-direction: column; */
    justify-content: start;
    align-items: center;
    gap: 15px;
    padding-top: 0;
  }

  .clear-history-cancel{
    background: #eaeaea;
    color: #000;
    border: none;
  }
  
  .clear-history-confirm{
    background: #fe0000;
    color: #fff;
    border: none;
  }

  @media (max-width: 375px) {
    #warning-modal p{
      font-size: 13px;
    }
  }

  .bg-warning {
    background-color: #ffc107;
    color: #212529;
  }


  @media (max-width: 480px) {
    .form-select, .form-control{
      width: 100% ;
    }
  }
  .filter-tab .tab-btn {
    border: none;
    background: #F2F2F2;
    border-radius: unset;
    color: #9C9C9C;
    padding: 7px 25px;
    transition: all .3s ease-in;
  }

  .filter-tab .tab-btn.active,  .filter-tab .tab-btn:hover{
    border: none;
    background: #92edce;
    color: #000000;
  }

  .filter-tab .tab-btn:first-child {
    border-radius: 20px 0 0 20px;
  }

  .filter-tab .tab-btn:last-child {
    border-radius: 0 20px 20px 0;
  }
`

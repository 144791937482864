/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import GlobalNavbar from '../components/GlobalNavbar'
import styled from 'styled-components'
import { getToStoreData } from '../plugins/storage';
import { TableContainer, TablePagination, TableSortLabel } from '@mui/material';
import usePagination from '../hooks/usePagination ';
import useSorting from '../hooks/useSorting ';
import axios from '../plugins/axios';
import toast from 'react-hot-toast';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const RSVPList = () => {

  const [RSVPList, setRSVPList] = useState([]);
  const [selectGroup, setSelectGroup] = useState({});
  const [searchItem, setSearchItem] = useState('');
  const [activeTab, setActiveTab] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [rsvpReminderCount, setRsvpReminderCount] = useState({});

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, setPage } = usePagination();
  const { order, orderBy, handleRequestSort, sortArray } = useSorting();

  const getRSVPList = () => {
    setIsLoading(true);
    const communication_uuid = getToStoreData("communication_uuid");
    axios.get(`/all_rsvp/${communication_uuid}`, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      setRSVPList(response.data.data || []);
    }).catch((error) => {
      console.log(error, "network error");
    }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    const selectedGroupData = getToStoreData('selectedGroup');

    if (selectedGroupData) {
      setSelectGroup(JSON.parse(selectedGroupData));
    }
    getRSVPList();
  }, []);

  const RSVPListFilter = useMemo(() => {
    return RSVPList.filter((item) => {
      return (
        item?.user_custom_fields?.["First name"]?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.user_custom_fields?.["Last name"]?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.user_custom_fields?.Company?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.user_custom_fields?.Mobile?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.location?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.dietary_specifics?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.rsvp_answer?.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
  }, [RSVPList, searchItem])

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "company") {
      if (b?.user_custom_fields?.Company < a?.user_custom_fields?.Company) {
        return -1;
      }
      if (b?.user_custom_fields?.Company > a?.user_custom_fields?.Company) {
        return 1;
      }
      return 0;
    } else if (orderBy === "firstName") {
      if (b?.user_custom_fields?.["First name"] < a?.user_custom_fields?.["First name"]) {
        return -1;
      }
      if (b?.user_custom_fields?.["First name"] > a?.user_custom_fields?.["First name"]) {
        return 1;
      }
      return 0;
    } else if (orderBy === "lastName") {
      if (b?.user_custom_fields?.["Last name"] < a?.user_custom_fields?.["Last name"]) {
        return -1;
      }
      if (b?.user_custom_fields?.["Last name"] > a?.user_custom_fields?.["Last name"]) {
        return 1;
      }
      return 0;
    } else if (orderBy === "phone") {
      if (b?.user_custom_fields?.["Mobile"] < a?.user_custom_fields?.["Mobile"]) {
        return -1;
      }
      if (b?.user_custom_fields?.["Mobile"] > a?.user_custom_fields?.["Mobile"]) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleClickTab = (tab) => {
    setActiveTab(tab);
    document.body.classList.add('overflow-hidden');
  }

  const closeModal = () => {
    setActiveTab("");
    setRsvpReminderCount({});
    document.body.classList.remove('overflow-hidden');
  }

  const modalRef = useRef(null);

  useEffect(() => {
    // Function to handle outside click
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal(); // Close modal if clicked outside the modal content
      }
    };

    // Attach event listener to detect clicks
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const GetRSVPReminder = () => {
    const communication_uuid = getToStoreData("communication_uuid");

    if (!communication_uuid) {
      toast.error("Communication uuid is a required.")
      return;
    }

    axios.get(`/get_rsvp_reminders_count?communication_invite_uuid=${communication_uuid}&invite_id=670bca`).then((response) => {

      const updatedData = response.data?.data?.filter((d) => {
        return activeTab === "RSVP" ? d.type === "rsvp_reminder" : d.type === "attend_reminder"
      })
      setRsvpReminderCount(updatedData?.length !== 0 ? updatedData[0] : {});
    }).catch((error) => {
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  useEffect(() => {
    if (activeTab) {
      GetRSVPReminder();
    }
  }, [activeTab])

  const handleSendRSVPReminder = () => {
    const userInfoData = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    if (!userInfoData) return;
    if (!communication_uuid) return;

    axios.post(`/send_rsvp_reminder`, {
      communication_invite_uuid: communication_uuid,
      user_id: JSON.parse(userInfoData).id,
      group_id: selectGroup.id,
      invite_id: "670bca"
    }).then((response) => {
      toast.success(response.data.message);
      closeModal();
    }).catch((error) => {
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const handleSendAttendanceReminder = () => {
    const userInfoData = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    if (!userInfoData) return;
    if (!communication_uuid) return;

    axios.post(`/send_attendance_reminder`, {
      communication_invite_uuid: communication_uuid,
      user_id: JSON.parse(userInfoData).id,
      group_id: selectGroup.id,
      invite_id: "670bca"
    }).then((response) => {
      toast.success(response.data.message);
      closeModal();
    }).catch((error) => {
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const handleExportExcel = () => {
    const communication_uuid = getToStoreData("communication_uuid");

    if (!communication_uuid) {
      toast.error("Communication UUID is missing.");
      return;
    }

    axios
      .get(`/export_rsvp/${communication_uuid}`, { responseType: "blob" })
      .then((response) => {
        const reader = new FileReader();
        reader.onload = () => {
          const csvData = reader.result;

          // Parse CSV into a workbook with proper date parsing
          const workbook = XLSX.read(csvData, {
            type: "string",
            cellDates: true
          });

          const sheet = workbook.Sheets[workbook.SheetNames[0]];

          // Convert to array of arrays with raw values and full year format
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            raw: false,
            dateNF: 'dd-mmm-yyyy' // Changed to full year format
          });

          const dateColumnIndex = 7;

          data?.forEach((row, index) => {
            if (index > 0 && row[dateColumnIndex]) {
              try {
                const parsedDate = new Date(row[dateColumnIndex]);

                if (!isNaN(parsedDate.getTime())) {
                  // Format date as DD-MMM-YYYY
                  row[dateColumnIndex] = parsedDate.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric' // Changed to show full year
                  }).replace(/ /g, '-');
                }
              } catch (e) {
                console.warn(`Could not parse date: ${row[dateColumnIndex]}`);
              }
            }
          });

          const newWorkbook = XLSX.utils.book_new();
          const newSheet = XLSX.utils.aoa_to_sheet(data);

          // Set column format for date column with full year
          const dateCol = XLSX.utils.encode_col(dateColumnIndex);
          const range = XLSX.utils.decode_range(newSheet['!ref']);

          for (let row = range.s.r + 1; row <= range.e.r; row++) {
            const cellRef = dateCol + (row + 1);
            if (newSheet[cellRef]) {
              newSheet[cellRef].z = 'dd-mmm-yyyy'; // Changed to full year format
            }
          }

          XLSX.utils.book_append_sheet(newWorkbook, newSheet, "RSVP Data");

          const excelBuffer = XLSX.write(newWorkbook, {
            bookType: 'xlsx',
            type: 'array'
          });

          const excelBlob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });

          saveAs(excelBlob, "export_rsvp.xlsx");
        };

        reader.readAsText(response.data);
      })
      .catch((error) => {
        toast.error(error.response ? error.response.data.message || "You have no attendees yet." : error.message);
      });
  };

  return (
    <Wrapper>
      <GlobalNavbar groupName={selectGroup.name} />
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col-lg-5">
            <h4 className='dark_title'>Manage your RSVPs </h4>
          </div>
          <div className="col-lg-7">
            <div className="d-flex rounded overflow-hidden filter-tab" style={{ marginLeft: "-150px" }}>
              <button type="button" className={`btn ${activeTab === "RSVP" ? "active" : ""} tab-btn`} onClick={() => handleClickTab("RSVP")}><img src="/images/popup-icon.png" alt="" className='img-fluid' width={"25px"} />Send reminder to RSVP</button>
              <div style={{ border: '1px var(--bs-border-style) #ffffff' }}></div>
              <button type="button" className={`btn ${activeTab === "attend" ? "active" : ""} tab-btn`} onClick={() => handleClickTab("attend")}><img src="/images/popup-icon.png" alt="" className='img-fluid' width={"25px"} />Send reminder to attend</button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
          {/* <h4 style={{ color: "#0c3e2b" }} className='mb-0'>RSVP List</h4> */}
          <input type="button" className="btn btn-primary btn-Vault" value="Download your attendees to Excel" onClick={handleExportExcel} />
          <input type='text' className='form-control w-auto' placeholder='Search' value={searchItem} onChange={(e) => {
            setSearchItem(e.target.value);
            if (page !== 0) {
              setPage(0)
            }
          }} />
        </div>
        <div className='table-responsive accordion-header'>
          <TableContainer>
            <table className="display table table-hover w-100 mb-1 border border-1">
              <thead className="table-header">
                <tr>
                  <th className="text-start"><TableSortLabel active={orderBy === "firstName"} direction={orderBy === "firstName" ? order : 'asc'} onClick={() => handleRequestSort("firstName")}>First Name</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "lastName"} direction={orderBy === "lastName" ? order : 'asc'} onClick={() => handleRequestSort("lastName")}>Last Name</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "company"} direction={orderBy === "company" ? order : 'asc'} onClick={() => handleRequestSort("company")}>Company</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "phone"} direction={orderBy === "phone" ? order : 'asc'} onClick={() => handleRequestSort("phone")}>Phone</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "location"} direction={orderBy === "location" ? order : 'asc'} onClick={() => handleRequestSort("location")}>Location</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "dietary_specifics"} direction={orderBy === "dietary_specifics" ? order : 'asc'} onClick={() => handleRequestSort("dietary_specifics")}>Dietary preferences</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "rsvp_answer"} direction={orderBy === "rsvp_answer" ? order : 'asc'} onClick={() => handleRequestSort("rsvp_answer")}>Status</TableSortLabel></th>
                </tr>
              </thead>
              <tbody>
                {RSVPListFilter.length > 0 ?
                  sortArray(RSVPListFilter, getComparator(order, orderBy))
                    .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((r, index) => (
                      <tr key={index}>
                        <td className="px-2 py-2">{r?.user_custom_fields?.["First name"]}</td>
                        <td className="px-2 py-2">{r?.user_custom_fields?.["Last name"]}</td>
                        <td className="px-2 py-2">{r?.user_custom_fields?.Company}</td>
                        <td className="px-2 py-2">{r?.user_custom_fields?.Mobile}</td>
                        <td className="px-2 py-2">{r.location ? r.location : <i className="fa-solid fa-minus"></i>}</td>
                        <td className="px-2 py-2">{r.dietary_specifics ? r.dietary_specifics : <i className="fa-solid fa-minus"></i>}</td>
                        <td className="px-2 py-2">{r.rsvp_answer}</td>
                      </tr>
                    ))
                  : (
                    <tr>
                      <td colSpan={7} className="text-center font-Bukra">{
                        !isLoading ? " No Data Found" : "Loading..."}</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            count={RSVPListFilter.length}
            page={page}
            className="bg-gray-50"
          />
        </div>
      </div>
      {activeTab === "attend" &&
        <div className="modal fade show" id="remind-RSVP's-to-attend" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.5)' }}  >
          <div className="modal-dialog" ref={modalRef}>
            <div className="modal-content" style={{ marginTop: "185px" }}>
              <div className="modal-header modal-custom-header">
                <h5 className="modal-title" id="staticBackdropLabel">Send reminder to attend</h5>
                {/* <button type="button" className="btn-close" onClick={closeModal}></button> */}
              </div>
              <div className="modal-body pt-0">
                <h6 className="card-text mb-0 pt-3">
                  This will send the invitation again, without RSVP buttons, to those who have RSVP'd.
                </h6>
                <div className='my-3'>
                  <div>
                    <span className="text-muted left-title">Total Reminders Sent: </span>
                    <span className="fw-bold text-black small">{rsvpReminderCount.invite_count || 0}</span>
                  </div>
                  <div>
                    <span className="text-muted left-title">Last Reminder sent on: </span>
                    <span className="fw-bold text-black small">{rsvpReminderCount.last_sent_date || "N/A"}</span>
                  </div>
                </div>
                <div className='text-center'>
                  <button className="btn btn-Vault px-4" onClick={handleSendAttendanceReminder}>Send reminder to attend</button>
                </div>
              </div>
            </div>
          </div>
        </div >}
      {activeTab === "RSVP" &&
        <div className="modal fade show" id="send-reminder-to-RSVP" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.5)' }} >
          <div className="modal-dialog" ref={modalRef}>
            <div className="modal-content" style={{ marginTop: "185px" }}>
              <div className="modal-header modal-custom-header">
                <h5 className="modal-title" id="staticBackdropLabel">Send reminder to RSVP</h5>
                {/* <button type="button" className="btn-close" onClick={closeModal}></button> */}
              </div>
              <div className="modal-body pt-0">
                <h6 className="card-text mb-0 pt-3">
                  This will send the invitation again ONLY to those invitees who have not yet RSVP'd.
                </h6>
                <div className='my-3'>
                  <div>
                    <span className="text-muted left-title">Total Reminders Sent: </span>
                    <span className="fw-bold text-black small">{rsvpReminderCount.invite_count || 0}</span>
                  </div>
                  <div>
                    <span className="text-muted left-title">Last Reminder sent on: </span>
                    <span className="fw-bold text-black small">{rsvpReminderCount.last_sent_date || "N/A"}</span>
                  </div>
                </div>
                <div className='text-center'>
                  <button className="btn btn-Vault px-4" onClick={handleSendRSVPReminder}>Send reminder to RSVP</button>
                </div>
              </div>
            </div>
          </div>
        </div >}
    </Wrapper>
  )
}

export default RSVPList;


const Wrapper = styled.div`
  .table-header tr th {background:#ededed;}
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{ margin-bottom: 0px !important;}
  .table > tbody > tr:nth-child(even) > td {background: #dbfef7;}
  .table > tbody > tr > td{color:#424242}
  .MuiTablePagination {color: #016b55;}
  
  /* tab-panel style part */
  .RSVP-tab.nav-tabs .nav-link{ 
    border: none;
    color: #3b4a46;
    font-weight: 600;
    transition: all .3s ease;
  }
  .nav-tabs .nav-link.active{
    /* border-bottom: 2px solid #87baa9; */
    color: #87baa9;
  }

    .filter-tab .tab-btn {
    border: none;
    background: #F2F2F2;
    border-radius: unset;
    color: #9C9C9C;
    padding: 7px 25px;
    transition: all 0.3s ease-in;
  }

  .filter-tab .tab-btn.active,  .filter-tab .tab-btn:hover{
    border: none;
    background: #92edce;
    color: #000000;
  }

  .filter-tab .tab-btn:first-child {
    border-radius: 20px 0 0 20px;
  }

  .filter-tab .tab-btn:last-child {
    border-radius: 0 20px 20px 0;
  }
  .card-text{
    font-weight: bold;
    color: #000000;
  }
  .left-title{
    font-weight: 500;
    color: #8f8f8f;
  }
`
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Navigate, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import axios from '../plugins/axios';
import toast from 'react-hot-toast';
import ical from 'ical-generator';
import { saveAs } from "file-saver";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RSVP = () => {
  let query = useQuery();
  const data = query.get("data") || '';
  const [RSVP, setRSVP] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [dietary_requirement, setDietary_requirement] = useState("");

  useEffect(() => {
    if (data) {
      decodeBase64(data);
    }
  }, [data]);

  const generateEventLink = (c) => {
    const formattedStartDate = moment(c.startDate + " " + c.startTime).utc().format('YYYYMMDDTHHmmss[Z]');
    const formattedEndDate = moment(c.endDate + " " + c.endTime).utc().format('YYYYMMDDTHHmmss[Z]');

    const baseURL = 'https://calendar.google.com/calendar/event?action=TEMPLATE';
    const params = new URLSearchParams({
      dates: `${formattedStartDate}/${formattedEndDate}`,
      text: c.title?.replaceAll("+", " "),
      details: c.details?.replaceAll("+", " "),
      reminders: JSON.stringify([{ method: "none" }])
    });

    return `${baseURL}&${params.toString()}`;
  };

  const body = `Hi,

I receive alerts from the Vault investor platform and thought you might find the service useful.
You can sign up at https://thevault.exchange and join the groups of the companies you follow.

Regards
TheVault`;

  const emailLink = `mailto:athrossell@thevault.exchange?subject=Introduction to the Vault investor alerts service&body=${encodeURIComponent(body)}`;


  const updateRsvp = (index, answer, location, invite_id, dietary_specifics = "") => {
    axios.post(`/rsvp`, {
      communication_invite_uuid: queryParams.communication_uuid,
      rsvp_answer: answer,
      dietary_specifics: dietary_specifics ? dietary_specifics : null,
      user_id: queryParams.user_id,
      location: location,
      invite_id
    }).then((response) => {
      setRSVP((preRSVP) => preRSVP.map((r, ind) => {
        return ind === index ? { ...r, rsvp_answer: answer, dietary_specifics_toggle: answer !== "No" ? r.dietary_specifics_toggle : false } : r
      }))
      toast.success(response.data.message);
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const handleDietarySpecificsChange = (index, dietary_specifics) => {
    setRSVP((preRSVP) => preRSVP.map((r, ind) => {
      return ind === index ? { ...r, dietary_specifics: dietary_specifics } : r
    }))
  }

  const handleDietarySpecificSave = (index, location) => {
    const selectedRSVP = RSVP.find((r, i) => i === index);

    updateRsvp(index, selectedRSVP.rsvp_answer, location, selectedRSVP.invite_id, selectedRSVP.dietary_specifics)
  }

  // Function to decode
  const decodeBase64 = (encodedString) => {
    try {
      if (!encodedString || typeof encodedString !== 'string') {
        throw new Error('Input is not a valid string');
      }

      const decodedString = atob(encodedString);
      const decodedData = decodeURIComponent(decodedString);

      if (!decodedData.includes("&")) {
        throw new Error('Decoded string does not contain expected query parameters');
      }

      const [jsonString, ...queryParamsString] = decodedData.split("&");

      let jsonData;
      try {
        jsonData = JSON.parse(jsonString);
      } catch (parseError) {
        throw new Error('Failed to parse JSON data');
      }

      const queryParams = queryParamsString.reduce((acc, param) => {
        const [key, value] = param.split("=");
        if (key && value) {
          acc[key] = value;
        } else {
          console.warn(`Invalid query parameter: ${param}`);
        }
        return acc;
      }, {});

      // Update state with the decoded data
      setQueryParams(queryParams);
      setDietary_requirement(queryParams?.dietary_requirement || "");

      if (jsonData) {
        setRSVP(jsonData || []);
      }

    } catch (error) {
      console.error("Error during Base64 decoding:", error.message);
      return null;
    }
  };

  const handleDietaryPreference = (index) => {
    setRSVP((preRSVP) => preRSVP.map((r, ind) => {
      return ind === index ? { ...r, dietary_specifics: r.dietary_specifics ? r.dietary_specifics : "", dietary_specifics_toggle: true } : r
    }))
  }

  const handleSave = (c) => {
    const start = c.startDate + " " + c.startTime
    const end = c.endDate + " " + c.endTime

    // Create a new iCal object
    const calendar = ical();

    // Add event to the calendar
    calendar.createEvent({
      start: start,
      end: end,
      summary: c.title.replaceAll("+", " "),
      description: c.details.replaceAll("+", " ")
    });

    // Generate the .ICS file content
    const content = calendar.toString();

    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });

    saveAs(blob, "event-schedule.ics");
  };

  if (!data) {
    return <Navigate to={"/communicate"} />
  }

  return (
    <>
      <Wrapper className="container d-flex align-items-center justify-content-center py-3">
        <div className="calendar-section">
          <div className="calendar-head py-2 px-4">
            <div style={{ width: "41px" }}>
              <img src="/images/Icon-black-Vault.svg" alt="logo" className='img-fluid' />
            </div>
            <h5 className='mb-0 heading-title'>Invitation</h5>
          </div>
          <div className='pb-3 px-4'>
            {RSVP.map((c, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="rsvp-card-body mt-3 flex-wrap pt-0">
                    <div className="rsvp-card-details">
                      {c.title && <h5 className='my-3 fw-normal'>{c.title.replaceAll("+", " ")}</h5>}

                      <p className='mb-0'>
                        <i className="fa-regular fa-calendar pe-2"></i>
                        {(c.startDate || c.endDate) &&
                          <span className='ps-1'>{c.startDate && moment(c.startDate).format("Do MMMM YYYY")}{c.endDate && " - " + moment(c.endDate).format("Do MMMM YYYY")}</span>}
                      </p>
                      <p className='mb-0'>
                        <i className="fa-regular fa-clock pe-2"></i>
                        {(c.startTime || c.endTime) &&
                          <span>{c.startTime && moment(c.startTime, "HH:mm").format("hh:mm a")}{c.endTime && " - " + moment(c.endTime, "HH:mm").format("hh:mm a")}</span>}
                      </p>

                      <p className='mb-0'>
                        <i className="fa-solid fa-location-dot pe-2"></i>
                        <span className='ps-1' style={{ wordBreak: "break-all" }}>{c.details.replaceAll("+", " ")}</span>
                      </p>
                    </div>
                    <div>
                      <div className="rsvp-card-buttons">
                        <button className={`btn yes ${c.rsvp_answer === "Yes" ? "active pe-none" : ""} ${c.rsvp_answer ? "" : "rsvp-yes"}`} onClick={() => {
                          if (c.rsvp_answer !== "Yes") {
                            updateRsvp(index, "Yes", c?.title?.replaceAll("+", " "), c.invite_id, c.dietary_specifics ? c.dietary_specifics : "")
                          }
                        }}>Yes</button>
                        <button className={`btn no ${c.rsvp_answer === "No" ? "active pe-none" : ""} ${c.rsvp_answer ? "" : "rsvp-yes"}`} onClick={() => {
                          if (c.rsvp_answer !== "No") {
                            updateRsvp(index, "No", c?.title?.replaceAll("+", " "), c.invite_id, c.dietary_specifics ? c.dietary_specifics : "")
                          }
                        }}>No</button>
                      </div>
                    </div>
                  </div>
                  {c.rsvp_answer === "Yes" &&
                    <div className="dietary-preference mt-3 p-3">
                      <h6 className='mb-1'>Add to calendar</h6>
                      <h6 className='download-link mt-2'>Download:
                        <button className='ms-1 me-2' onClick={() => handleSave(c)}>
                          <img src="/images/ics-icon.png" alt="ics-icon" className='img-fluid' width={30} height={30} />
                        </button>
                        <a target="_blank" rel="noreferrer"
                          href={generateEventLink(c)} className="text-decoration-none"
                        >
                          <img src="/images/google-calendar.png" alt="google-calendar-icon" className='img-fluid' width={30} height={30} />
                        </a>
                      </h6>
                    </div>}
                  {c.dietary_specifics_toggle && <>
                    <div className="dietary-preference mt-3">
                      <label htmlFor="preference" className="rsvp-card-label">Dietary preference:</label>
                      <select id="preference" className="rsvp-dropdown form-select" value={c.dietary_specifics || ""} onChange={(e) => handleDietarySpecificsChange(index, e.target.value)}>
                        <option value="">{dietary_requirement?.replaceAll("+", " ") || "----"}</option>
                        <option value="NONE">None</option>
                        <option value="Vegetarian">Vegetarian</option>
                        <option value="Fish only">Fish only</option>
                        <option value="Kosher">Kosher</option>
                        <option value="Halal">Halal</option>
                        {/* {dietary_requirement && <option value={dietary_requirement}>{dietary_requirement}</option>} */}
                      </select>
                      <div>
                        <button className="btn btn-Vault mt-2 px-3 py-1" onClick={() => handleDietarySpecificSave(index, c?.title?.replaceAll("+", " "))}>Save</button>
                      </div>
                    </div>
                  </>}
                  {c.rsvp_answer === "Yes" && !c.dietary_specifics_toggle &&
                    <div className="dietary-preference mt-3">
                      <h4>Your dietary preference</h4>
                      <p>
                        <NavLink to="#" className="dietary-preference-link" onClick={(e) => {
                          e?.preventDefault()
                          handleDietaryPreference(index)
                        }}
                        >
                          If you are attending a physical event, please select your dietary preference.
                        </NavLink>
                      </p>
                    </div>}
                </React.Fragment>
              )
            })}
            <footer className="rsvp-card-footer my-3 pt-3">
              <a href={emailLink}><i className="fa-solid fa-user-group"></i> Would you like to add a colleague to The Vault?</a>
              <a href='mailto:athrossell@thevault.exchange'> <i className="fa-regular fa-envelope"></i> Drop us an email should you need assistance</a>
            </footer>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  min-height: 98vh;

  .calendar-head{
    background-color: #92edce;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px 10px 0 0;
  }

  .calendar-head p{
    color: #8a959b;
    font-size: 15px;
  }

  .heading-title{
    color: #2f2f2f;
  }

  .calendar-section{
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 560px;
  }

  .rsvp-card-body {
    display: flex;
    /* align-items: center;  */
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    flex-direction: column;
  }

  .rsvp-card-details i{
    font-size: 15px;
    color: #000000;
  }
  /* .rsvp-card-details {
    flex: 1;
    text-align: left;
  } */

  .rsvp-card-details p {
    margin: 0;
    /* color: #a9a9a9; */
    color: #696969;
    font-size: 14px;
    letter-spacing: 0.3px;
  }

  .rsvp-card-details h3 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  .rsvp-card-label {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    display: block;
  }

  .rsvp-dropdown {
    width: 50%;
    padding: 8px;
    font-size: 14px;
    color: #333;
    border: 2px solid #4CAF50; /* Green border */
    border-radius: 20px;
    outline: none;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    margin-top: 8px;
  }

  .rsvp-dropdown option {
    color: #333;
  }

  .btn-vault{
    font-weight: 500 !important;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .rsvp-card-buttons {
    display: inline-flex;
    border: 1px solid #606060;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 8px;
  }

  .rsvp-card-buttons .btn {
    border: none;
    background: transparent;
    font-weight: 600;
    padding: 10px 8px;
    cursor: pointer;
    font-size: 14px;
    width: 50px;
    text-align: center;
    color: #606060;
    position: relative;
    border-radius: unset;
  }

  .rsvp-card-buttons .btn.active{
    background-color: #606060;
    color: #ffff;
  }

  .rsvp-card-buttons .btn.yes.rsvp-yes::after {
    content: "";
    position: absolute;
    top: 4px; 
    bottom: 4px;
    right: 0;
    width: 1px;
    background-color: #606060;
  }

  .dietary-preference {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }

  .dietary-preference h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }

  .dietary-preference p {
    margin: 8px 0 0 0;
  }

  .dietary-preference-link {
    font-size: 14px;
    color: rgb(39 80 65); /* Dark green */
  }

  .dietary-preference-link:hover {
    text-decoration: none;
  }

  .rsvp-card-footer {
    border-top: 1px solid #c7c7c7;
  }

  .rsvp-card-footer  a{
    color: #131313;
    text-decoration: none;
    /* color: #696969; */
    font-size: 15px;
    display: inline-block;
    font-weight: 600;
  }

  .download-link{
    font-weight: 400;
  }

  .download-link button{
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0px;
  }

  @media (max-width: 991px) {
    .rsvp-card-details{
      flex: unset;
    }
  }
`

export default RSVP

import { useState } from 'react';

const useSorting = (initialOrder = 'asc', initialOrderBy = '') => {
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "company") {
      if (b.custom_fields?.Company < a.custom_fields?.Company) {
        return -1;
      }
      if (b.custom_fields?.Company > a.custom_fields?.Company) {
        return 1;
      }
      return 0;
    } else if (orderBy === "firstName") {
      if (b.custom_fields?.["First name"] < a.custom_fields?.["First name"]) {
        return -1;
      }
      if (b.custom_fields?.["First name"] > a.custom_fields?.["First name"]) {
        return 1;
      }
      return 0;
    } else if (orderBy === "lastName") {
      if (b.custom_fields?.["Last name"] < a.custom_fields?.["Last name"]) {
        return -1;
      }
      if (b.custom_fields?.["Last name"] > a.custom_fields?.["Last name"]) {
        return 1;
      }
      return 0;
    } else if (orderBy === "city") {
      if (b.custom_fields?.city < a.custom_fields?.city) {
        return -1;
      }
      if (b.custom_fields?.city > a.custom_fields?.city) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const sortArray = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  return {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    sortArray,
  };
};

export default useSorting;

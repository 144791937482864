import { createContext, useState } from "react";
import axiosInstance from "../plugins/axios";
import { addToStoreData, getToStoreData } from "../plugins/storage";

const userContext = createContext();

export const UserProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [recipientData, setRecipientData] = useState([]);

  const getUserData = (group_id) => {
    setIsLoading(true);
    axiosInstance.get(`/user_list/${group_id}`).then(async (response) => {
      setRecipientData(response?.data?.flat() || []);
      addToStoreData("recipientData", JSON.stringify(response?.data?.flat() || []));
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    })
  };

  return (
    <userContext.Provider value={{
      getUserData,
      isLoading,
      recipientData
    }}>
      {children}
    </userContext.Provider>
  )
}

export default userContext
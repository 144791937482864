import { createCustomBlock, t, mergeBlock } from "easy-email-pro-core";
import React from "react";
import { ElementCategory } from "easy-email-pro-core";

export const CustomBlockType = {
  LOGO: "Calendar",
};

const defaultData = {
  data: {
    content: "Add to Calendar",
    location: "Calendar title",
    startDate: "2024-05-01",
    endDate: "2024-05-30",
    startTime: "13:00",
    endTime: "16:00",
    details: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime et magni voluptate. Esse quaerat, ipsa nisi culpa quasi non amet.",
  },
  attributes: {
    height: "",
    "padding-top": "10px",
    "padding-bottom": "10px",
    "padding-right": "25px",
    "padding-left": "25px",
    "align": "center",
    border: "0",
    "border-width": "1px",
    "border-style": "solid",
    "border-color": "#AAAAAA",
    "border-radius": "3px",
    "text-decoration": "none",
    "font-style": "none",
    width: "auto",
    "inner-padding-top": "10px",
    "inner-padding-bottom": "10px",
    "inner-padding-left": "25px",
    "inner-padding-right": "25px",
    "background-color": "#414141",
    // "container-background-color": "#ffffff",
    "color": "#ffffff",
    "font-size": "13px",
    "line-height": "120%",
    "letter-spacing": "0px"
  }
};

export const CustomLogo = createCustomBlock({
  get name() {
    return t("Add to Calendar");
  },
  defaultData: defaultData,
  type: CustomBlockType.LOGO,
  void: true,
  create: (payload) => {
    const data = {
      type: CustomBlockType.LOGO,
      data: {
        ...defaultData.data,
      },
      attributes: {
        ...defaultData.attributes,
      },
      children: [],
    };
    return mergeBlock(data, payload);
  },
  category: ElementCategory.BUTTON,
  render(params) {
    const { node } = params;

    const { data } = node;
    const {
      "border-enabled": borderEnabled,
      "border-width": borderWidth,
      "border-style": borderStyle,
      "border-color": borderColor,
      "inner-padding-top": innerPaddingTop,
      "inner-padding-bottom": innerPaddingBottom,
      "inner-padding-left": innerPaddingLeft,
      "inner-padding-right": innerPaddingRight,
      ...attributes
    } = node.attributes;

    const top = innerPaddingTop;
    const right = innerPaddingRight;
    const bottom = innerPaddingBottom;
    const left = innerPaddingLeft;
    const innerPadding = `${top} ${right} ${bottom} ${left}`;

    const border = borderEnabled
      ? `${borderWidth || "1px"} ${borderStyle || "solid"} ${borderColor || "#AAAAAA"
      }`
      : "";
    return (
      <mj-button
        padding-bottom={attributes["padding-bottom"] || "10px"}
        padding-top={attributes["padding-top"] || "10px"}
        padding-left={attributes["padding-left"] || "25px"}
        padding-right={attributes["padding-right"] || "25px"}
        container-background-color={attributes["container-background-color"]}
        background-color={attributes["background-color"] || "#414141"}
        href={`${process.env.REACT_APP_FRONTEND_URL}/calendar?title=${data.location}&startDate=${data.startDate}&startTime=${data.startTime}&endDate=${data.endDate}&endTime=${data.endTime}&details=${data.details}`}
        height={attributes["height"] || "auto"}
        align={attributes["align"] || "center"}
        border={border || "none"}
        border-radius={attributes["border-radius"] || "20px"}
        color={attributes.color || "#ffffff"}
        font-family={attributes["font-family"] || "Arial, sans-serif"}
        font-size={attributes["font-size"] || "13px"}
        font-style={attributes["font-style"] || "normal"}
        font-weight={attributes["font-weight"] || "400"}
        letter-spacing={attributes["letter-spacing"] || "0px"}
        line-height={attributes["line-height"] || "120%"}
        text-decoration={attributes["text-decoration"] || "none"}
        width={attributes["width"] || "auto"}
        inner-padding={innerPadding || "10px 25px"}
        data-calendar={`${data.location} -${data.startDate} - ${data.startTime} - ${data.endDate} -${data.endTime} - ${data.details}`}
      >
        {data.content}
      </mj-button>
    );
  },
});
import React, { useEffect, useRef, useState } from 'react'
import { getToStoreData } from '../plugins/storage';
import axios from '../plugins/axios';
import toast from 'react-hot-toast';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

const SendToAllModal = ({ closeModal }) => {

  const [isLoading, setIsLoading] = useState(false);

  const sendToAll = () => {
    const communication_uuid = getToStoreData("communication_uuid");
    const communicate_data = getToStoreData("communicate_data");
    const selectGroupData = getToStoreData('selectedGroup');

    if (!selectGroupData) return;
    if (!communicate_data) return;
    setIsLoading(true);
    axios.post('/send-to-all-emails', {
      body: JSON.parse(communicate_data).email_body ? JSON.parse(JSON.parse(communicate_data).email_body) : "",
      communication_uuid,
      group_id: JSON.parse(selectGroupData).id,
      name: JSON.parse(communicate_data).title,
      subject: JSON.parse(communicate_data).title
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      closeModal();
      const successModalReference = new bootstrap.Modal(document.getElementById('success-modal'));
      document.getElementById("success-message").innerText = "Your email has been successfully sent"
      successModalReference.show();
      setTimeout(() => {
        successModalReference?.hide();
      }, 5000)
    }).catch((error) => {
      toast.error(error.response ? error.response.data.message : error.message);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const modalRef = useRef(null);

  useEffect(() => {
    // Function to handle outside click
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal(); // Close modal if clicked outside the modal content
      }
    };

    // Attach event listener to detect clicks
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div id="send-to-all-modal" className="modal fade show" style={{ display: 'block', backgroundColor: "rgb(25 28 28 / 50%)" }} >
      <div className="modal-dialog" ref={modalRef} >
        <div className="modal-content" style={{ marginTop: "185px", marginLeft: "140px" }}>
          <div className="modal-header modal-custom-header">
            <h5 className="modal-title" id="staticBackdropLabel">Send to all</h5>
            {/* <button type="button" className="btn-close" onClick={closeModal}></button> */}
          </div>
          <div className="modal-body my-1">
            <p className="text-label">You are sending an email to all members of this group. All good?</p>
            <hr />
            <div className="mt-3 d-flex gap-3 justify-content-end">
              <button className="btn btn-primary btn-gray" type="button" disabled={isLoading} onClick={closeModal}>Cancel</button>
              <button type="submit" className="btn btn-Vault" style={{ marginRight: "10px" }} onClick={sendToAll}>{isLoading ? "Sending..." : "Send to all"}</button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SendToAllModal
import React, { useEffect, useState } from 'react'
import GlobalNavbar from '../components/GlobalNavbar'
import { addToStoreData, getToStoreData, removeToStoreData } from '../plugins/storage';
import SelectGroupModal from '../components/SelectGroupModal';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import axios from '../plugins/axios';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import moment from 'moment';

const Communicate = () => {

  const [loggedInMessage, setLoggedInMessage] = useState("You are logged in to Communicator");
  const [modalData, setModalData] = useState("");
  const [communicateData, setCommunicateData] = useState([]);
  const [selectGroup, setSelectGroup] = useState({});

  const history = useNavigate();

  useEffect(() => {
    const selectGroupData = getToStoreData("selectedGroup");
    if (selectGroupData) {
      setLoggedInMessage(loggedInMessage + ' Editing Group: ' + JSON.parse(selectGroupData).name);
      setSelectGroup(JSON.parse(selectGroupData));
    }
  }, []);

  // get data for backend
  const getCommunicateData = () => {
    axios.get(`/communications/${selectGroup.id}`).then((response) => {
      if (response.data.success) {
        setCommunicateData(response.data.data);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (selectGroup.id) {
      getCommunicateData();
    }
  }, [selectGroup])

  const handleGroupSelect = (data) => {
    if (!data) return;
    const message = "You are logged in to Communicator";
    setLoggedInMessage(message + ' Editing Group: ' + data.name);
    setSelectGroup(data);
    modalData && modalData.hide();
  };

  const showModal = () => {
    const myModal = new bootstrap.Modal(document.getElementById('group-modal'));
    setModalData(myModal);
    myModal.show();
  }

  const redirectToEditor = () => {
    removeToStoreData("subject");
    removeToStoreData("communicate_data");
    removeToStoreData("communication_uuid");
    history('/editor');
  }

  const handleDuplicate = (communication_uuid) => {
    axios.post(`/duplicate-communication/${communication_uuid}`)
      .then(response => {
        if (response.data.success) {
          getCommunicateData();
        }
      })
      .catch(error => {
        console.error('Error duplicate item:', error);
      });
  }

  const handleDelete = (communication_uuid) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#92edce",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/delete-communication/${communication_uuid}`)
          .then(response => {
            if (response.data.success) {
              getCommunicateData();
            }
          })
          .catch(error => {
            console.error('Error deleting item:', error);
          });
      }
    });
  }

  const handleUpdate = (data) => {
    addToStoreData("communication_uuid", data.uuid);
    addToStoreData("communicate_data", JSON.stringify(data));
    history('/editor');
  }

  return (
    <Wrapper>
      <GlobalNavbar LoggedIn={loggedInMessage} groupName={selectGroup.name} />
      {selectGroup.id &&
        <div className="container my-4" >
          <div className="head-section d-flex justify-content-between align-items-center mb-3 flex-wrap gap-2">
            <div className="title">
              <h3 className="mb-1 dark_title">{selectGroup.name}</h3>
              <p className="mb-0">Your dispatches</p>
            </div>
            {communicateData && communicateData.length > 0 &&
              <div className="create d-flex gap-2 justify-content-center align-items-center" >
                <input type="button" className="btn btn-primary btn-Vault" value="Create new" onClick={redirectToEditor} />
                <NavLink to="/templates" className="btn btn-Vault-secondary" >Templates</NavLink>
                <input type="button" className="btn btn-Vault-secondary" value="Change Group" onClick={showModal} />
              </div>}
          </div>
          {communicateData && communicateData.length < 1 ?
            <div className="not-found-section d-flex align-items-center justify-content-center flex-column" >
              <h3>Good to see you!</h3>
              <div className="row">
                <div className="col-md-7 mx-auto">
                  <p>Your design lineup is as empty as a library on a Friday night! Time to add some designs to the shelf, don't you think?</p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center align-items-center">
                <input type="button" className="btn btn-primary btn-Vault" value="Create new" onClick={redirectToEditor} />
                <NavLink to="/templates" className="btn btn-Vault-secondary" >Templates</NavLink>
                <input type="button" className="btn btn-Vault-secondary" value="Change Group" onClick={showModal} />
              </div>
            </div> :
            <div className="tabs-section">
              {communicateData.map((curElem) => {
                return (
                  <div className="card-box d-flex justify-content-between p-4 mb-4" key={curElem.id}>
                    <div className="card-info">
                      <div className="d-flex align-items-center flex-wrap gap-2 mb-2">
                        <h5 className="mb-0" onClick={() => handleUpdate(curElem)}>{curElem.title}</h5>
                        {curElem.notification_type && Object.keys(curElem.notification_type).length > 0 ? (
                          Object.entries(curElem.notification_type).map(([action, count]) => (
                            <label className="label-title" key={action}>{action} <span>{count}</span></label>
                          ))
                          // <>
                          //   <label className="label-title">Opened <span>{curElem.notification_type["Open"] || 0}</span></label>
                          //   <label className="label-title">Delivery <span>{curElem.notification_type["Delivery"] || 0}</span></label>
                          //   <label className="label-title">Clicks <span>{curElem.notification_type["Click"] || 0}</span></label>
                          //   <label className="label-title">Bounces <span>{curElem.notification_type["Bounce"] || 0}</span></label>
                          // </>
                        ) : (
                          <>
                            <label className="label-title">Open <span>0</span></label>
                            {/* <label className="label-title">Delivery <span>0</span></label> */}
                            <label className="label-title">Click <span>0</span></label>
                            <label className="label-title">Send <span>0</span></label>
                          </>
                        )}
                      </div>
                      <p className="mb-0">
                        <span className="pe-1">{moment(curElem.created_at).format("MMM Do,YYYY HH:mm")}</span>|
                        <span className="px-1" style={{ color: "#696969" }}>Created by:</span>
                        {curElem.created_by_name ?
                          <span>{curElem.created_by_name}</span> :
                          <span><i className="fa-solid fa-minus"></i></span>}
                      </p>
                    </div>
                    <div className="card-action d-flex gap-3">
                      <div className="copy-action action-btn" onClick={() => handleDuplicate(curElem.uuid)}>
                        <i className="fa-regular fa-copy"></i>
                      </div>
                      <div className="delete-action action-btn" onClick={() => handleDelete(curElem.uuid)}>
                        <i className="fa-regular fa-trash-can"></i>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
      }
      <SelectGroupModal handleGroupSelect={handleGroupSelect} showModal={showModal} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .not-found-section{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 2px solid #b3f1dc;
    padding: 50px 30px;
  }

  .not-found-section h3{
    font-weight: 600;
    margin-bottom: 18px;
  }

  .not-found-section p{
    text-align: center;
    color: #57786d;
    margin-bottom: 25px;
    font-size: 18px;
  }

  .btn-Vault{
    font-weight: 500 !important;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .btn-Vault-secondary{
    background: none;
    border: 1px solid #92edce;
    color: #000;
    font-weight: 500;
  }

  .btn-Vault-secondary:hover{
    background: #013321;
    border: 1px solid #013321;
    color: #fff;
  }
`
export default Communicate
